import {
  Checkbox,
  Popover,
  ScrollArea,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { IconFilter } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import OdLabelBadge from '@/fine-tune/components/label-badge/od-label-badge/od-label-badge';
import { useLabels } from '@/fine-tune/hooks/query-hooks/use-labels/use-labels';
import {
  useActiveFilters,
  useParametersStore,
  useParametersStoreActions
} from '@/fine-tune/stores/parameters-store';
import { ParametersKeys } from '@/fine-tune/stores/parameters-store/parameters.store.types';
import { DEFAULT_INSIGHTS_PARAMS } from '@/fine-tune/types/query.types';
import { ListState } from '@/fine-tune/types/state.types';

// Exporting for testing only
export const generateInitialValues = (
  labels: string[] = [],
  currentValues?: string[]
): ListState[] => {
  return labels?.map((label) => {
    const parsedLabel = label || STRING_PLACEHOLDER;
    return {
      label: parsedLabel,
      checked: !currentValues?.length || currentValues?.includes(parsedLabel),
      key: parsedLabel
    };
  });
};

interface ColumnFilterProps {
  columnId: 'gold' | 'pred' | 'class' | 'galileo_error_type' | string;
  uniqueValues?: string[];
  targetTrigger?: React.ReactNode;
  label?: string;
}

const ColumnFilter = ({
  columnId,
  uniqueValues,
  targetTrigger,
  label = 'Search filters'
}: ColumnFilterProps) => {
  const { classFilter, goldFilter, metaFilter, predFilter } =
    useParametersStore((s) => ({
      classFilter: s.classFilter,
      goldFilter: s.goldFilter,
      predFilter: s.predFilter,
      metaFilter: s.metaFilter
    }));
  const { setParameters, setMetaParameters } = useParametersStoreActions();

  const activeFilters = useActiveFilters();
  const [filterSearchTerm, setFilterSearchTerm] = useState('');

  const { data } = useLabels();

  const isGoldPredOrClass = ['gold', 'pred', 'class'].includes(columnId);

  let queryFilterById: string[] | undefined;
  switch (columnId) {
    case 'gold':
      queryFilterById = goldFilter;
      break;
    case 'pred':
      queryFilterById = predFilter;
      break;
    case 'class':
      queryFilterById = classFilter;
      break;
    case 'galileo_error_type':
    case 'noise_type':
      queryFilterById = metaFilter?.find((filter) => filter.name === columnId)
        ?.isin as string[];
      break;

    default:
      queryFilterById = activeFilters
        ?.find(([name]) => name === 'metaFilter')?.[1]
        ?.find(({ name }: { name: string }) => name === columnId)?.isin;
      break;
  }

  const [values, handlers] = useListState<ListState>(
    generateInitialValues(uniqueValues ?? data?.labels, queryFilterById)
  );

  const [checkedValues, setCheckedValues] = useState<(string | null)[]>([]);
  useEffect(() => {
    setCheckedValues(
      values
        .filter(({ checked }) => checked)
        .map(({ label }) => (label === STRING_PLACEHOLDER ? null : label))
    );
  }, [values]);

  // To handle when filters have been removed using the active filter badges
  useEffect(() => {
    handlers.setState(
      generateInitialValues(uniqueValues || data?.labels, queryFilterById)
    );
  }, [queryFilterById, data?.labels?.length]);

  const key = `${columnId}Filter` as ParametersKeys;
  const allChecked = values.every(({ checked }) => checked);

  const indeterminate = !allChecked && values.some(({ checked }) => checked);

  const filteredItems: JSX.Element[] = [];

  values.forEach(({ checked, key, label }, index) => {
    if (`${label}`?.toLowerCase()?.includes(filterSearchTerm?.toLowerCase())) {
      filteredItems.push(
        <Checkbox
          checked={checked}
          color='brand'
          data-testid={`selected-filter-checkbox-${index}`}
          key={key}
          label={
            <Tooltip
              withinPortal
              label={label}
              // Hack to only show tooltip if label is truncated
              opened={label?.length > 26 ? undefined : false}
            >
              {columnId === 'classes' ? (
                <OdLabelBadge label={label} />
              ) : (
                <Text className='truncate'>{label}</Text>
              )}
            </Tooltip>
          }
          ml={33}
          mt='xs'
          style={{
            label: {
              maxWidth: 200
            }
          }}
          onChange={(event) =>
            handlers.setItemProp(index, 'checked', event.currentTarget.checked)
          }
        />
      );
    }
  });

  const applyFilters = () => {
    if (!isGoldPredOrClass) {
      const _checkedValues = checkedValues.map((value) =>
        value === 'true' || value === 'false' ? value === 'true' : value
      );
      return setMetaParameters([
        {
          name: columnId,
          isin: allChecked ? [] : _checkedValues
        }
      ]);
    }

    if (allChecked) {
      return setParameters({ [key]: DEFAULT_INSIGHTS_PARAMS[key] });
    }

    const isQueryFilterById =
      queryFilterById && queryFilterById !== checkedValues;
    const isNotAllChecked = !queryFilterById && !allChecked;

    if (isQueryFilterById || isNotAllChecked) {
      return setParameters({ [key]: checkedValues });
    }
  };

  const handleClose = () => {
    applyFilters();
    setFilterSearchTerm('');
  };

  return (
    <Popover
      withArrow
      withinPortal
      data-testid='column-filter'
      position='bottom'
      width={300}
      onClose={handleClose}
    >
      <Popover.Target>
        <UnstyledButton aria-label='open column filter' color='dimmed'>
          {targetTrigger ?? <IconFilter color='#706C89' size={16} />}
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown>
        <TextInput
          label={label}
          mb='sm'
          size='xs'
          onChange={({ target }) => setFilterSearchTerm(target?.value)}
        />
        <Checkbox
          checked={allChecked}
          color='brand'
          data-testid='selected-filters-all-toggle'
          indeterminate={indeterminate}
          label={`Selected ${label ?? 'filters'} (${
            values.filter(({ checked }) => checked)?.length
          })`}
          onChange={() =>
            handlers.setState((current) =>
              current.map((value) => ({ ...value, checked: !allChecked }))
            )
          }
        />
        <ScrollArea.Autosize mah={250}>{filteredItems}</ScrollArea.Autosize>
      </Popover.Dropdown>
    </Popover>
  );
};

export default ColumnFilter;
