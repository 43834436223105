import { Text } from '@mantine/core';
import { MouseEvent } from 'react';

import LabelBadgeWithPopover from '@/fine-tune/components/label-badge/label-badge-with-popover/label-badge-with-popover';
import { parseMetaColumnName } from '@/fine-tune/data-parsers/parse-meta-column-name/parse-meta-column-name';
import { getParametersStoreActions } from '@/fine-tune/stores/parameters-store';
import { InsightsRow } from '@/fine-tune/types/query.types';
import { MetaColumn } from '@/fine-tune/types/response.types';

interface GetParsedMetaColumnsArgs {
  meta: MetaColumn[];
}

export const getParsedMetaColumns = (metaCols: GetParsedMetaColumnsArgs) => {
  if (!metaCols?.meta || !Array.isArray(metaCols?.meta)) {
    return [];
  }

  const { setMetaParameters } = getParametersStoreActions();

  return metaCols.meta.map((col) => ({
    label: parseMetaColumnName(col.name),
    accessor: col.name,
    isFilterable: col.is_categorical,
    isSortable: col.is_continuous,
    isMeta: true,
    isSmartColumn: Boolean(col.name.match(/galileo/)),
    uniqueValues: col?.unique_values?.map((val) => val?.toString()),
    uniqueValuesFull: col?.unique_values_full?.map((val) => val?.toString()),
    uniqueValuesCount: col?.unique_count_full,
    cell: ({
      value,
      isTruncated,
      isBoolean
    }: {
      value: any;
      isTruncated?: boolean;
      isBoolean?: boolean;
    }) => {
      let parsedValue = value;

      if (typeof value === 'number' && col?.name !== 'galileo_text_length') {
        parsedValue = parseFloat(value?.toFixed(3));
      }

      if (isBoolean) {
        parsedValue = value ? 'True' : 'False';
      }

      const addMetaFilter = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setMetaParameters([{ name: col.name, isin: [value] }]);
      };

      return (
        <Text
          data-testid='cell-value'
          lineClamp={isTruncated ? 1 : undefined}
          size='sm'
          style={{ cursor: 'pointer' }}
          onClick={addMetaFilter}
        >
          {parsedValue}
        </Text>
      );
    }
  }));
};

export const mapMltcLabels = (
  data: InsightsRow,
  accessor: 'mltc_golds' | 'mltc_preds'
) => {
  const secondaryAccessor =
    accessor === 'mltc_golds' ? 'mltc_preds' : 'mltc_golds';
  const primaryLabels = data?.[accessor];
  const secondaryLabels = data?.[secondaryAccessor];

  const deps = data?.[`${accessor}_deps`];

  const samples = primaryLabels
    ?.map((label, i) => {
      return {
        text: label,
        data_error_potential: deps?.[i]
      };
    })
    ?.sort(
      (a, b) => (b?.data_error_potential || 0) - (a?.data_error_potential || 0)
    );

  return samples?.map((sample, i) => {
    const text = sample?.text || '';

    const isGhostOrMissed = !secondaryLabels?.includes(text);

    return (
      <LabelBadgeWithPopover
        isGhostOrMissed={isGhostOrMissed}
        key={`${text}-${i}`}
        sample={sample}
        value={text}
      />
    );
  });
};
